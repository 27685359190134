<script setup lang="ts">
import type { MatchmakingItem, MatchmakingPost } from '~/types/types'
import AppLink from '@/components/app/AppLink.vue'
import { useGeoDataStore } from '~/stores/geoData'
import { useMatchmakingDataStore } from '~/stores/matchmaking'
import AppMap from '@/components/map/AppMap.vue'
import { LayerPresets } from '~/types/map'
const { find } = useStrapi()
const { slug } = useRoute().params

type Page = {
  title: string
  excerpt: string
  slug: string
  content: string
  template: 'general' | 'with-preset-links'
}

const { data: page } = await useAsyncData(
  'pages',
  () =>
    find<Page>('pages', {
      populate: '*',
      filters: {
        slug: {
          $eq: slug,
        },
      },
    }),
  { transform: (page) => page.data[0]?.attributes }
)

useSeoMeta({
  title: () => `Drivmedla - ${page.value?.title}`,
  description: () => page.value?.excerpt,
  ogTitle: () => `Drivmedla - ${page.value?.title}`,
  ogDescription: () => page.value?.excerpt,
  ogLocale: () => 'sv_SE',
  ogSiteName: () => 'Drivmedla',
  ogUrl: () => `${useRuntimeConfig().public.baseURL}${useRoute().fullPath}`,
  ogImage: () => `${useRuntimeConfig().public.baseURL}/_nuxt/assets/_logo-horizontal-GREEN.svg`,
})
</script>
<template>
  <main v-if="page">
    <AppHero :title="page?.title" :description="page?.excerpt" />
    <LayoutSection>
      <div class="flex justify-content-center my-6">
        <div class="col-12 sm:col-12 md:col-10 lg:col-7">
          <MarkdownRenderer :content="page?.content" />
        </div>
      </div>
    </LayoutSection>
    <LayoutSection v-if="page.template === 'with-preset-links'">
      <AppHeading title="Utforska omställningen i Sverige" type="h2" size="large" />
      <MapPresetsList />
    </LayoutSection>
  </main>
</template>
<style lang="scss" scoped></style>
